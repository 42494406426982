import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import LayoutPage from '@components/LayoutPage';

export default function FourOhFour() {
  const { t } = useTranslation();

  return (
    <LayoutPage>
      <div className="flex items-center justify-center w-full pt-32">
        <div className="flex flex-col text-gray-700 lg:flex-row lg:space-x-16 lg:space-x-reverse">
          <div className="order-1 max-w-md px-2 text-sm md:text-base lg:px-0">
            <header className="mb-6">
              <h2 className="text-4xl font-bold leading-none text-gray-100 select-none lg:text-6xl">
                {t('notFound.title')}
              </h2>
              <h3 className="text-xl font-light text-gray-300 leading-normal lg:text-3xl md:text-3xl">
                {t('notFound.content')}
              </h3>
            </header>
            <p className="max-w-sm mb-5 text-gray-500 leading-5 md:leading-7">
              {t('notFound.footer')}
            </p>
          </div>

          <div className="max-w-lg">
            <div className="w-96">
              <Image
                layout="intrinsic"
                height={300}
                width={300}
                src="https://static.platzi.com/static/images/error/img404.png"
                alt="error404"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutPage>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
